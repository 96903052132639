export default {
  'Items por página' : 'Itens por página',
  de: 'de',
  'y': 'e',
  English : 'Inglês',
  Portuguese : 'Português',
  Spanish: 'Espanhol',
  Salir: 'Sair',
  Usuarios: 'Usuários',
  Usuario: 'Do utilizador',
  Paises: 'Países',
  'Brasil' : 'Brasil',
  'República Dominicana' : 'República Dominicana',
  'Granada' : 'Granada',
  'Guayana Francesa' : 'Guiana Francesa',
  'Guyana' : 'Guiana',
  País: 'País',
  Regiones: 'Regiões',
  Region: 'Região',
  Escuelas: 'Escolas',
  Escuela: 'Escola',
  Perfil: 'Perfil',
  Evaluación: 'Avaliação',
  "Estadísticas": 'Estatísticas',
  "Por evaluación": 'Por avaliação',
  "Por escuela": 'Por escola',
  "Por continente": 'Por continente',
  "Por país": 'Por país',
  "Por región": 'Por região',
  "Latinoamerica y el Caribe": 'América Latina e Caribe',
  'Paises asignados' : 'Países atribuídos',
  'Regiones asignadas' : 'Regiões atribuídas',
  'Escuelas asignadas' : 'Escolas atribuídas',
  'PQI Evaluation': 'Avaliações PQI',
  'All rights Reserved.' : 'Todos os direitos reservados.',
  'Powered by' : 'Distribuído por',
  Home : 'Home',
  'Ir al contenido' : 'Ir para o conteúdo',
  FAQs : 'FAQs',
  Tutorial : 'Tutorial',
  Evaluaciones: 'Avaliações',
  Resultados: 'Resultados',
  Período: 'Período',
  Título: 'Título',
  Nota: 'Nota',
  Instrumentos: 'Instrumentos',
  Instrumento: 'Instrumento',
  'Crear Usuario': 'Criar Usuário',
  'Editar Usuario': 'Editar Usuário',
  'Editar información de Usuario': 'Editar informações do usuário',
  'Crear Region': 'Criar Região',
  'Editar Region': 'Editar Região',
  'Crear Escuela': 'Criar Escola',
  'Crear Evaluación': 'Criar Avaliação',
  'Eliminar evaluación' : 'Eliminar Avaliação',
  'Es super administrador': 'Ele é um super administrador',
  'No tiene permisos para crear un usuario super administrador.': 'Você não tem permissão para criar um usuário superadministrador.',
  Nombre: 'Nome',
  Files: 'Arquivos',
  Add: 'Adicionar',
  Close: 'Fechar',
  'Gráfico': 'Gráfico',
  'Close modal' : 'Fechar modal',
  Descargar: 'Baixar',
  Acciones: 'Ações',
  Editar: 'Editar',
  Crear: 'Criar',
  Opciones: 'Opções',
  'Opción': 'Opção',
  Modalidad: 'Modalidade',
  Nivel: 'Nível',
  Puntaje: 'Pontuação',
  'Ayuda': 'Ajuda',
  'Imprimir gráficos' : 'Imprimir gráficos',
  'General': 'Geral',
  'Por categoria': 'Por categoria',
  'Por indicador': 'Por indicador',
  'Plan de acción' : 'Plano de ação',
  'Tipo de gestión': 'Tipo de gestão',
  'Resultados generales': 'Resultados gerais',
  'Puntaje de la categoría': 'Pontuação da categoria',
  'Puntaje del indicador' : 'Pontuação do indicador',
  'Distribución de los criterios de valoración': 'Distribuição dos critérios de avaliação',
  Rol: 'Papel',
  'Teléfono': 'Telefone',
  'Buscar por nombre de usuario': 'Pesquisar por nome de usuário',
  'Buscar por nombre de region': 'Pesquisar pelo nome da região',
  'Buscar por nombre de país': 'Pesquisar pelo nome da país',
  'Buscar por nombre de escuela': 'Pesquisar pelo nome da escola',
  'Buscar por nombre de evaluación': 'Pesquisar pelo nome da avaliação',
  'Nombre completo': 'Nome completo',
  'Nombre de usuario': 'Nome de usuário',
  Contraseña: 'Senha',
  'Contraseña anterior' : 'Senha Antiga',
  'Contraseña nueva' : 'Nova senha',
  'Repetir la nueva contraseña' : 'Repita a nova senha',
  'Confirmar contraseña': 'Confirmar senha',
  '¿Desea recibir emails automaticamente?': 'Você quer receber e-mails automaticamente?',
  'No, gracias': 'Não, obrigado',
  Suscribirse: 'Subscrever',
  Cancelar: 'Cancelar',
  Siguiente: 'Seguindo',
  Date: 'Data',
  Semana: 'Semana',
  Detalles: 'Detalhes',
  Agregar: 'Adicionar',
  Ver: 'Ver',
  Upload: 'Carregar',
  Guardar: 'Guardar',
  Cerrar: 'Cerrar',
  Asignar: 'Atribuir',
  'Progreso': 'Progresso',
  'Asignar todas': 'Atribuir todas',
  'Asignar selección': 'Atribuir seleção',
  Filtrar: 'Filtrar',
  Finalizar: 'Finalizar',
  'No hay paises asignados para este usuario.' : 'Não há países atribuídos a este usuário.',
  'No hay regiones asignadas para este usuario.' : 'Não há regiões atribuídas a este usuário.',
  'No hay escuelas asignadas para este usuario.': 'Não há escolas atribuídas a este usuário.',
  'Sin información disponible' : 'Nenhuma informação disponível',
  'Cambiar Contraseña': 'Alterar a senha',
  'Cambiar contraseña de usuario': 'alterar a senha do usuário',
  'Selecciona...': 'Escolher...',
  'Seleccione un archivo' : 'Selecione um arquivo',
  'Campo requerido': 'Campo obrigatório',
  'Email inválido': 'Email inválido',
  'Nombre de la Region': 'Nome da região',
  Administrador: 'Administrador',
  'Representante de país': 'Representante do país',
  'Coordinador regional': 'Coordenador regional',
  'Representante escolar': 'Representante da escola',
  'Nombre de la Escuela': 'Nome da Escola',
  'Nombre del proyecto': 'Nome do projeto',
  'Profesiones': 'Profissões',
  'Cantidad de estudiantes': 'Número de estudantes',
  'Estudiantes con discapacidades': 'Alunos com deficiência',
  'Estudiantes con multiple discapacidades': 'Alunos com deficiência múltipla',
  'Estudiantes con discapacidades visuales': 'Alunos com deficiência visual',
  'Estudiantes con sordoceguera': 'Alunos com surdocegueira',
  'Cantidad de profesores': 'Número de professores',
  'Cantidad de otros profesionales': 'Número de outros profissionais',
  'Personal total': 'Equipe completa',
  'Educación regular': 'Educação regular',
  'Educación especial': 'Educação especial',
  'Educación domiciliaria hospitalaria': 'Educação em casa de hospital',
  'Centro de primera infancia': 'Centro da primeira infância',
  'Público': 'Público',
  'Privado': 'Privado',
  'Preescolar': 'Pré-escola',
  'Escolar primario': 'Escola',
  'Escolar secundario': 'Liceu',
  'Instrumento Escolar': 'Instrumento Escolar',
  'Instrumento Preescolar': 'Instrumento Pré-escolar',
  '¿Estás seguro de eliminar el usuario': 'Tem a certeza de que quer apagar o utilizador',
  '¿Estás seguro de eliminar la region': 'Tem a certeza de que quer remover a região',
  '¿Estás seguro de eliminar la escuela': 'Tem a certeza de eliminar a escola',
  '¿Estás seguro de eliminar esta evaluación' : 'Tem a certeza de eliminar a avaliação',
  'Eliminar': 'Eliminar',
  'No hay datos disponibles.': 'Não há dados disponíveis.',
  Indicadores: 'Indicadores',
  'Prácticas-autonomía': 'Prácticas-autonomía',
  'Trabajo colaborativo': 'Trabalho colaborativo',
  'Comunicación transversal': 'Comunicação transversal',
  'Inclusión educativa': 'Inclusão Educacional',
  'Debe cargar algún indicador' : 'Deve carregar algum indicador',
  'para poder visualizar gráficos' : 'para poder visualizar os gráficos',
  Comentarios: 'Comentários',
  'Comentario completo': 'Comentário completo',
  'Conocimiento sobre el estudiante': 'Conhecimento sobre o estudante',
  'Planificación actividades y contenidos': 'Planejamento, atividades e conteúdos',
  'Organización del entorno educativo': 'Organização do ambiente educacional',
  'Gestión escolar': 'Gestão escolar',
  'Sobre los profesionales': 'Sobre os profissionais',
  'Sobre la participación de los estudiantes': 'Sobre a participação dos estudantes',
  'Sobre la familia': 'Sobre a família',
  'Información del niño/niña' : 'Informações da criança',
  'Programa en educación temprana: planificación y actividades' : 'Programa de Educação Precoce: Planejamento e Atividades',
  'Organización del entorno' : 'Organização do Meio Ambiente',
  'Gestión del servicio' : 'Gestão de Serviços',
  'Prácticas profesionales' : 'Práticas do professor',
  'Interacciones de calidad entre niño/niña y adulto' : 'Interações de qualidade entre a criança e o adulto',
  'Participación de la familia' : 'Participação da Família',
  //schoolIndicatorParser:
  'El equipo de profesionales trabaja de forma apropiada con el/la estudiante, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.': 'A equipe de profissionais trabalha de forma adequada com o estudante, em um processo de avaliação colaborativo e coordenado, demonstrando que está informada sobre o impacto da deficiência múltipla e/ou surdocegueira e interage comunicativamente de forma adequada.',
  'Las evaluaciones sensoriales funcionales (visión y audición) se completan en cada niño.': 'Avaliações sensoriais funcionais (visão e audição) são realizadas em cada criança.',
  'El personal del programa coordina o facilita evaluaciones de visión y audición con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).': 'A equipe do programa coordena ou facilita exames de visão e audição com especialistas qualificados em oftalmologia ou audição (oftalmologista, ortoptista, otorrinolaringologista, etc.).',
  'Las evaluaciones se realizan durante rutinas naturales permitiendo observar las interacciones espontáneas del niño.': 'As avaliações são realizadas durante rotinas naturais, permitindo a observação das interações espontâneas da criança.',
  'Los resultados de la evaluación son revisados periódicamente y se utilizan de forma actualizada en la planificación y en el seguimiento.': 'Os resultados da avaliação são revisados ​​periodicamente e utilizados de forma atualizada no planejamento e monitoramento.',
  'Las planificaciones diarias, las actividades y las estrategias de enseñanza están claramente relacionadas con las metas, los objetivos y los intereses de los estudiantes incluidos en el PEI.': 'Programações diárias, atividades e estratégias de ensino estão claramente relacionadas às metas, objetivos e interesses dos alunos incluídos no PEI.',
  'Las actividades del aula incluyen áreas del currículo expandido y están alineadas con el currículo nacional.': 'As atividades em sala de aula incluem áreas do currículo expandido e estão alinhadas com o currículo nacional.',
  'Las actividades y contenidos se proponen a través de situaciones problemáticas que motivan al estudiante a participar, compartir y hacer elecciones.': 'As atividades e conteúdos são propostos por meio de situações problemáticas que motivam o aluno a participar, compartilhar e fazer escolhas.',
  'Hay tiempo suficiente para las transiciones entre actividades que respeten las habilidades individuales y permitan la comprensión y participación tranquila de los estudiantes.': 'Há tempo suficiente para transições entre as atividades, que respeitam as habilidades individuais e permitem que os alunos entendam e participem de forma tranquila.',
  'TIEMPO: El día se organiza de manera fluida, conectando las actividades entre sí (por ejemplo, escribir una lista de artículos que se deben comprar para una actividad de cocina, ir a la tienda a comprarlos, completar la actividad de cocina, escribir sobre ella en un diario).': 'TEMPO: O dia é organizado de maneira fluida, conectando as atividades entre si (por exemplo, escrever uma lista de itens para comprar para uma atividade de culinária, ir à loja para comprá-los, concluir a atividade de cozinhar, escrever sobre ela em um diário).',
  'Hay un horario claramente definido, consistente y accesible para los estudiantes.': 'Existe um horário claramente definido, consistente e acessível para os alunos.',
  'CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej: para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido).': 'CONTEXTO: O espaço e as áreas de trabalho são seguros e adaptados às necessidades de apoio derivadas da deficiência (por exemplo, para deficiência visual: iluminação, contraste, espaços definidos etc.; para perda auditiva: cobertura de piso e janelas para reduzir o ruído)',
  'Se controla el umbral sensorial en el entorno para fomentar la atención y el máximo acceso al aprendizaje.': 'O limiar sensorial no ambiente é controlado para promover atenção e máximo acesso à aprendizagem.',
  'Se utilizan múltiples ubicaciones en la escuela y dentro de la comunidad para fomentar la enseñanza en entornos naturales.': 'Vários locais são usados ​​na escola e na comunidade para incentivar a aprendizagem em ambientes naturais.',
  'Los materiales didácticos son apropiados para las edades y los niveles de desarrollo de los estudiantes y son accesibles para su uso en el aula.': 'Os materiais didáticos são apropriados para as idades e níveis de desenvolvimento dos estudantes e são acessíveis para uso em sala de aula.',
  'Los miembros del personal demuestran saber cómo ayudar a los estudiantes anticipando y hablando sobre las actividades. Por ejemplo, usando calendarios, proporcionando un nivel apropiado de asistencia y cantidad adecuada de pistas para completar una tarea.': 'Os membros da equipe demonstram saber como ajudar os alunos antecipando e conversando sobre as atividades. Por exemplo, usando calendários, proporcionando um nível adequado de assistência e uma quantidade adequada de pistas para concluir uma tarefa.',
  'Hay suficiente personal docente representando a los diferentes niveles de gestión para atender las necesidades de aprendizaje de cada niño.': 'Existe pessoal docente suficiente representando os diferentes níveis de gestão para atender às necessidades de aprendizagem de cada criança.',
  'Hay servicios de apoyo organizados para atender las necesidades educativas del alumno (fisioterapia, orientación y movilidad, etc.).': 'Existem serviços de apoio organizados para atender às necessidades educacionais do aluno (fisioterapia, orientação e mobilidade etc.).',
  'El personal recibe supervisión y apoyo de forma organizada por alguien con conocimientos y habilidades en la educación de niños con DM/DV/SC.': 'A equipe é supervisionada e recebe apoio organizado de alguém com conhecimento e habilidades na educação de crianças com MDS/DV/SC.',
  'Hay oportunidades planificadas para que todo el personal, incluidos los padres, equipo de paraprofesionales y administradores participen en actividades de formación e intercambio de información sobre cómo se trabaja y da respuesta a las necesidades de estudiantes con SC/DM/DS.': 'Oportunidades são planejadas para que todos os funcionários, incluindo pais, equipe paraprofissional e administradores participem de atividades de capacitação e intercâmbio de informações sobre como trabalhar e responder às necessidades de alunos com MDS/DV/SC.',
  'Hay un sistema eficaz de comunicación entre el hogar y la escuela (visitas al hogar, libros de comunicación, etc.) para que el personal y las familias puedan mantenerse bien informados sobre el alumno.': 'Existe um sistema eficaz de comunicação entre casa e escola (visitas domiciliares, cartilhas de comunicação, etc.) para que equipe escolar e familiares fiquem bem informados sobre o aluno.',
  'Los administradores del programa interactúan con los funcionarios a nivel local, regional y nacional que son responsables de la supervisión del programa.': 'Os administradores do programa interagem com profissionais a níveis local, regional e nacional responsáveis ​​pela supervisão do programa.',
  'Los Planes Educativos Individualizados (PEI) están documentados y tienen un formato específico para su registro.': 'Os Planos Educacionais Individualizados (PEI) são documentados e possuem um formato específico para registro.',
  'FORMACIÓN EN COMUNICACIÓN: El personal entiende, reconoce y responde a los modos de comunicación más naturales de sus estudiantes, mostrando una relación cómoda y mutuamente agradable.': 'FORMAÇÃO EM COMUNICAÇÃO: A equipe entende, reconhece e responde aos modos de comunicação mais naturais de seus estudantes, demonstrando uma relação confortável e mutuamente agradável.',
  'El personal es capaz de modelar el siguiente nivel superior de comunicación fomentando el desarrollo de la comunicación de sus estudiantes.': 'A equipe é capaz de modelar o próximo nível superior de comunicação, fomentando o desenvolvimento da comunicação de seus estudantes.',
  'El personal entiende la intención comunicativa de los comportamientos desafiantes en los estudiantes y responde de forma adecuada.': 'A equipe entende a intenção comunicativa dos comportamentos desafiantes nos estudantes e responde de forma adequada.',
  'TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí.': 'TRABALHO EM EQUIPE: Os professores e administradores se comunicam claramente entre si.',
  'El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos.': 'Os profissionais da equipe trabalham cooperativamente a partir de suas funções disciplinares e compartilham responsabilidades para o desenvolvimento de planos educacionais.',
  'Los estudiantes tienen acceso a los apoyos comunicativos durante todo el día.': 'Os estudantes têm acesso aos apoios que favorecem a comunicação ao longo do dia.',
  'Cada estudiante participa plenamente en todas las actividades, independientemente de su nivel de desarrollo.': 'Cada estudante participa plenamente em todas as atividades, independientemente de seu nivel de desenvolvimento.',
  'El estudiante se manifiesta motivado, atento e involucrado en la actividad que se le propone.': 'O estudante mostra-se motivado, atento e envolvido na atividade proposta.',
  'El estudiante reconoce y es reconocido por el resto de sus compañeros.': 'O estudante reconhece e é reconhecido pelos demais colegas.',
  'Se comparte un sistema comunicativo en común entre los compañeros promoviendo sostener interacciones y lazos de amistad.': 'Um sistema de comunicação comum é compartilhado entre pares, promovendo sustentação nas interações e laços de amizade.',
  'El estudiante usa los diferentes niveles de apoyo disponibles para participar.': 'O estudante utiliza os diferentes níveis de suporte ou apoio disponíveis para participar.',
  'Los estudiantes colaboran entre sí.': 'Os estudantes colaboram entre si.',
  'Se fomenta que los miembros de la familia compartan información y expresen sus expectativas durante el proceso de evaluación, generando una comunicación fluida y cercana, y permitiendo una toma de decisiones equitativa y cooperativa.': 'Os familiares são incentivados a compartilhar informações e expressar suas expectativas durante o processo de avaliação, gerando uma comunicação fluida e próxima, e permitindo a tomada de decisão de forma equitativa e cooperativa.',
  'Hay actividades coordinadas que promueven la creación de redes entre las familias.': 'Existem atividades articuladas que promovem a criação de redes entre as famílias.',
  'Las familias participan activamente en las actividades del programa.': 'As famílias participam ativamente das atividades do programa.',
  'El personal del programa se reúne con las familias en sus hogares y/o en la escuela de forma regular.': 'A equipe do programa se reúne regularmente com as famílias em suas casas e/ou na escola.',
  'Se ofrecen talleres de formación para las familias que tienen hijos en el programa, incluyendo apoyo e instrucción en estrategias de comunicación eficaces para su hijo.': 'Oficinas de capacitação são oferecidas para as famílias que têm filhos no programa, incluindo apoio e instrução em estratégias de comunicação eficazes para seu filho.',

  //preschoolIndicatorParser:
  'El equipo de profesionales trabaja de forma apropiada con cada niño/a, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.' : 'A equipe de profissionais trabalha de forma apropriada com cada criança em um processo de avaliação colaborativa e coordenada, demonstrando conhecimento sobre o impacto de múltiplas deficiências e/ou surdocegueira, e interagindo de forma apropriada em termos de comunicação.',
  'Las evaluaciones funcionales (sensoriales y motrices) se completan en cada niño/a.' : 'É realizada a avaliação funcional (sensorial e motora) para cada criança.',
  'El personal del programa de educación temprana, coordina o facilita un trabajo en red sobre evaluaciones con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).' : 'A equipe do programa de educação precoce coordena ou facilita uma colaboração em rede com avaliações de especialistas qualificados em oftalmologia e atenção auditiva (oftalmologista, optometrista, otorrinolaringologista, etc).',
  'Los resultados de la evaluación son revisados periódicamente (por ejemplo cada 3/6 meses) y se utilizan de forma actualizada en la planificación y en el seguimiento.' : 'Os resultados da avaliação são revisados ​​periodicamente (por exemplo, a cada 3/6 meses) e utilizados de forma atualizada no planejamento e monitoramento.',
  'La organización del programa incentiva el juego (variados y adecuados a la edad) y las interacciones sociales para alcanzar logros en las diferentesáreas de desarrollo.' : 'A organização do programa incentiva o jogo (variado e adequado à idade) e as interações sociais para alcançar marcos nas diferentes áreas de desenvolvimento.',
  'Se ofrecen rutinas diarias a través de las cuales el niño/niña puedan anticipar distintas actividades en diferentes espacios' : 'São oferecidas rotinas diárias através das quais a criança pode antecipar diferentes atividades em diferentes espaços.',
  'Se integran las rutinas básicas de alimentación, cuidado e higiene para el desarrollo de habilidades sociales.' : 'As rotinas básicas de alimentação, cuidado e higiene são integradas para promover o desenvolvimento de habilidades sociais.',
  'Las actividades proponen el aprendizaje a traves de los diferentes sentidos.' : 'As atividades propõem a aprendizagem através dos diferentes sentidos.',
  'Los miembros del personal demuestran saber cómo ayudar a los estudiantes proporcionando apropiado nivel de asistencia y promoviendo la independencia y la seguridad para la participación de los niños/niñas.' : 'Os membros da equipe demonstram conhecimento sobre como ajudar os estudantes, fornecendo níveis adequados de apoio e promovendo independência e segurança para a participação das crianças.',
  'TIEMPO: Hay una rutina consistente en la organización de la jornada diaria.' : 'TEMPO: Existe uma rotina consistente na organização da agenda diária.',
  'El día se organiza de manera fluida conectando las actividades.' : 'O dia é organizado de forma fluída, conectando as atividades.',
  'CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej:para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido; para las discapacidades físicas accesibilidad en el entorno)' : 'CONTEXTO: O espaço e as áreas de trabalho são seguros e adaptados às necessidades de apoio decorrentes das deficiências (por exemplo, para deficiências visuais: iluminação, contraste, espaços definidos, etc.; para perda auditiva: revestimento de piso e janelas para reduzir o ruído; para deficiências físicas: acessibilidade ambiental).',
  'Se utilizan múltiples ubicaciones en el servicio y dentro de la comunidad para fomentar la enseñanza en entornos naturales y apropiados.' : 'São utilizados múltiplos locais no serviço e na comunidade para promover o ensino em ambientes naturais e apropriados.',
  'Los materiales utilizados en las actividades (rotulados, con contraste, texturas agradables, etc) proponen, a partir de su disposición y caracteríticas, la exploración activa y la acción del niño sobre el objeto.' : 'Os materiais utilizados nas atividades (etiquetados, com contraste, texturas agradáveis, etc.) propõem, por meio de sua disposição e características, a exploração ativa e a interação da criança com o objeto.',
  'Hay suficiente personal, representando a los diferentes niveles de gestión, para atender las necesidades de aprendizaje de cada niño.' : 'Existe pessoal suficiente, representando diferentes níveis de gestão, para atender às necessidades de aprendizagem de cada criança.',
  'Hay oportunidades planificadas para que toda la comunidad educativa participe en actividades formación e intercambio sobre como trabajar y dar respuesta a las necesidades de los niños/niñas con DM/DV/SC' : 'Existem oportunidades planejadas para que toda a comunidade educacional participe de atividades de formação e intercâmbio sobre como trabalhar e responder às necessidades de crianças com múltiplas deficiências/deficiências visuais/surdocegueira.',
  'Hay un sistema eficaz de comunicación entre el hogar y el servicio (visitas al hogar, libros de comunicación…) para que el personal y las familias puedan mantenerse bien informados sobre el niño/niña' : 'Existe um sistema eficaz de comunicação entre o lar e o serviço (visitas domiciliares, livros de comunicação, etc.) para garantir que a equipe e as famílias possam se manter bem informadas sobre a criança.',
  'Los administradores del servicio trabaja en red con organizaciones, ministerios u otros programas para organizar la transición de los niños/niñas a otros servicios.' : 'Os administradores do serviço trabalham em rede com organizações, ministérios ou outros programas para organizar a transição das crianças para outros serviços.',
  'Existen planes individualizados documentados con un formato específico en el que participen colaborativamente entre todos los miembros del equipo' : 'Existem planos individualizados documentados em um formato específico nos quais todos os membros da equipe participam colaborativamente.',
  'El personal brinda oportunidades para que el niño o niña pueda realizar elecciones a lo largo de la jornada.' : 'A equipe oferece oportunidades para que a criança possa fazer escolhas ao longo do dia.',
  'El personal brinda oportunidades para que el niño o niña inicie la comunicación como por ejemplo la toma de turno, pedir más.' : 'A equipe oferece oportunidades para que a criança inicie a comunicação, como por exemplo, tomar turnos e solicitar mais.',
  'TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí. Por ejemplo comparten información relacionada de los niños/niñas para trabajar de forma consistente y coordinada' : 'TRABALHO EM EQUIPE: Professores e administradores se comunicam claramente entre si. Por exemplo, eles compartilham informações relevantes sobre as crianças para trabalhar de forma consistente e coordenada.',
  'El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos y para la transición a los futuros servicios preescolares.' : 'A equipe trabalha cooperativamente a partir de suas funções disciplinares e compartilha responsabilidades para o desenvolvimento de planos educacionais e para a transição para futuros serviços pré-escolares.',
  'Las interacciones entre niño/niña y adulto son saludables, satisfactorias y en un clima amigable (el adulto y/o figura de apego propone interacciones que afianzan el vínculo destacando los aspectos positivos y alentando a la participación).' : 'As interações entre a criança e o adulto são saudáveis, satisfatórias e ocorrem em um ambiente amigável (o adulto ou figura de apego propõe interações que fortalecem o vínculo, destacando os aspectos positivos e incentivando a participação).',
  'Cada niño/niña participa en todas las actividades, independientemente de su nivel de desarrollo' : 'Cada criança participa de todas as atividades, independentemente do seu nível de desenvolvimento.',
  'El niño/niña reconoce y es reconocido por el resto de sus compañeros.' : 'A criança reconhece e é reconhecida pelos demais colegas.',
  'Los niños/as comparten un sistema comunicativo en común, promoviendo interacciones y lazos de amistad entre pares.' : 'As crianças compartilham um sistema de comunicação comum, promovendo interações e laços de amizade entre os colegas.',
  'Se les propone a los niños y niñas modelos de comunicación apropiados para cada uno/una.' : 'São propostos modelos de comunicação apropriados a cada criança, levando em consideração suas necessidades individuais.',
  'Se involucra a las familias en la implementación de los planes y en la organización de la transición de los niños/niñas a los nuevos servicios.' : 'As famílias são envolvidas na implementação dos planos e na organização da transição das crianças para novos serviços.',
  'Las familias implementan en sus casas estrategias y orientaciones brindadas por el programa' : 'As famílias implementam estratégias e orientações fornecidas pelo programa em suas casas.',

  //Action-plan
  'Esta evaluación no tiene un plan de acción' : 'Esta avaliação não possui um plano de ação',
  '¿Cuáles son las necesidades y problemas identificados para atender prioritariamente en el proceso de cambio sobre el que se realiza el plan?' : 'Quais são as necessidades e problemas identificados a serem priorizados no processo de mudança no qual o plano está baseado?',
  '¿Cuáles son los centros de interés que motivan y movilizan al equipo de trabajo para participar del proceso de cambio?' : 'Quais são os centros de interesse que motivam e mobilizam a equipe de trabalho para participar do processo de mudança?',
  '¿Cuáles son los obstáculos y las dificultades que se pueden encontrar al momento de implementar el plan de acción para resolver los problemas identificados?' : 'Quais são os obstáculos e dificuldades que podem ser encontrados ao implementar o plano de ação para resolver os problemas identificados?',
  '¿De qué recursos disponemos en el corto, mediano y largo plazo para llevar adelante un plan de cambio?' : 'Quais recursos temos no curto, médio e longo prazo para executar um plano de mudança?',
  '¿Cuáles son los resultados de la evaluación de los indicadores de calidad de Perkins que se deciden priorizar en el plan de acción?' : 'Quais são os resultados da avaliação dos indicadores de qualidade de Perkins que são decididos para serem priorizados no plano de ação?',
  'Meta a cumplir': 'Meta a ser cumprida',
  'Resultado esperado': 'Resultado esperado',
  'Actividades para cumplir meta': 'Atividades para cumprir a meta',
  'Editando': 'Editando',
  'Estado' : 'Estado',
  'Realizado' : 'Realizado',
  'En progreso' : 'Em progresso',
  'No realizado' : 'Não Realizado',
  'Completar campo': 'Campo completo',
  'No hay información aun cargada': 'Ainda não há informações carregadas',
  'Aún no hay actividades asignadas': 'Nenhuma atividade atribuída ainda',
  'Aún no hay observaciones': 'Ainda não há observações',
  'Meta': 'Meta',
  'Observaciones': 'Observações',
  'Fecha': 'Data',
  'Agregar meta' : 'Adicionar meta',
  'Imprimir' : 'Imprimir',
  'Presencial': 'Em pessoa',
  'Virtual': 'Virtual',
  'Reuniones de seguimiento.': 'Reuniões de acompanhamento.',
  'Comunicaciones por e-mail, WhatsApp.': 'Comunicações por e-mail, WhatsApp.',
  'Monitoreo sobre la implementación de contenidos recibidos desde la formación de la academia.': 'Acompanhamento da implementação dos conteúdos recebidos desde a formação da academia.',
  'Monitoreo sobre actividades singulares/distintivas, prácticas referidas a casos individuales.': 'Acompanhamento de atividades singulares/distintivas, práticas referentes a casos individuais.',
  'Otras formaciones.': 'Outras formações.',
  'Otras actividades.': 'Outras atividades',
  'Actividad': 'Atividade',
  'Próximos acuerdos': 'Próximos acordos',
  'Categoría': 'Categoria',

  'Las puntuaciones deben sumar 100': 'As pontuações devem somar 100',
  'Puntuaciones guardadas con éxito': 'Pontuações salvas com sucesso',
  'Peso teórico': 'Peso teórico',
  'No aplica': 'Não se aplica',
  'No se encuentra evidencia': 'Nenhuma evidência encontrada',
  'Focal': 'Focal',
  'En expansión': 'Em expansão',
  'Transversal': 'Transversal',
  'Política institucional': 'Política institucional',
  'Cultura innovadora': 'Cultura inovadora',
  'Indicadores de calidad de Perkins': 'Indicadores de qualidade de programas',
  'Estos indicadores son el resultado de una extensa discusión entre el personal profesional de Perkins Internacional y respetados colegas de diferentes regiones donde este programa presta servicios.': 'Estes indicadores são o resultado de uma extensa discussão entre a equipe profissional da Perkins Internacional e colegas respeitados de diferentes regiões atendidas por este programa.',
  'Esta versión de 2021 para Latinoamérica surge de la iniciativa por llevar adelante un proceso de validación y actualización en función de los cambios de paradigma, la experiencia ganada por el equipo de especialistas y de la diversidad de entornos y participantes que están involucrados en las diferentes experiencias educativas con estudiante con sordoceguera, discapacidad sensorial y/o discapacidad múltiple.': 'Esta versão de 2021 para América Latina surge da iniciativa por levar adiante um processo de validação e atualização em função das mudanças de paradigmas, da experiência conquistada pela equipe de especialistas e da diversidade de entornos e participantes que estão envolvidos nas diferentes experiências educativas com estudantes com surdocegueira, deficiência sensorial e/ou deficiência múltipla.',
  'La perspectiva de revisión del instrumento es la que entiende a la evaluación como un proceso inherente a la educación que aporta una mirada crítica y reflexiva a la experiencia educativa. El resultado de la evaluación no marca un lugar de certezas absolutas, sino que permite analizar la experiencia educativa en su complejidad, entendida como una intersección de acciones, de implementación de estrategias y proyecciones': 'A perspectiva de revisão do instrumento é aquela que entende a avaliação como um processo inerente à educação que proporciona um olhar crítico e reflexivo sobre a experiência educativa.A avaliação não marca um lugar de certezas absolutas, mas permite a análise da experiência educacional em sua complexidade, entendida como um cruzamento de ações, implementação de estratégias e projeções.',
  'Se espera que esta herramienta sea utilizada por los programas para la autorreflexión y autoevaluación, como parte del proceso de desarrollo de sus planes internos de formación y desarrollo del programa.': 'Espera-se que esta ferramenta seja utilizada pelos programas para autorreflexão e autoavaliação, como parte do processo de desenvolvimento de seus planos internos de formação e desenvolvimento dos programas.',
  'Asimismo, también puede ser utilizada como una herramienta para los evaluadores externos, para medir la capacidad de un programa, desarrollar un plan para apoyar el futuro y calcular el impacto de este apoyo en la construcción de capacidad.': 'Também pode ser usado como uma ferramenta para avaliadores externos avaliarem a capacidade de um programa, desenvolverem um plano para apoio futuro e estimarem o impacto desse apoio na capacitação.',
  'Principios': 'Princípios',
  'LOS PRINCIPIOS ORIENTADORES DE ESTA REVISIÓN SON': 'OS PRINCÍPIOS ORIENTADORES DESTA REVISÃO SÃO',
  'Prácticas Promotoras de Autonomía': 'Práticas Promotoras de Autonomia',
  'Experiencias educativas que, por su valor innovador y transformador en la implementación de estrategias, disponibilidad de material, accesibilidad universal responden a las necesidades y demandas de los estudiantes para el desarrollo de su autonomía y participación.': 'Experiências educativas que, por seu valor inovador e transformador na implementação de estratégias, disponibilidade de material, acessibilidade universal, respondem às necessidades e demandas dos estudantes para o desenvolvimento de sua autonomia e participação.',
  'Trabajo Colaborativo': 'Trabalho Colaborativo',
  'Dinámicas de trabajo que convocan a la participación de maestros, autoridades y familia, cuyas metas y estrategias son diseñadas e implementadas conjuntamente para lograr un trabajo articulado.': 'Dinâmicas de trabalho que convocam à participação de professores, autoridades e famílias, cujas metas e estratégias são desenhadas e implementadas conjuntamente para alcançar um trabalho articulado.',
  'Comunicación Transversal (principio y método)': 'Comunicação Transversal (princípio e método)',
  'Se considera a la comunicación como una filosofía de trabajo y como una estrategia metodológica.': 'Se considera a comunicação como uma filosofia de trabalho e como uma estratégia metodológica.',
  'Como filosofía de trabajo: significa considerarla fundamental para el desarrollo del aprendizaje y las relaciones interpersonales, por lo cual se debe estudiar y planificar en todas las áreas de participación de los estudiantes.': 'Como filosofia de trabalho: significa considerá-la fundamental para o desenvolvimento da aprendizagem e das relações interpessoais, portanto, todas as áreas de participação dos alunos devem ser estudadas e planejadas.',
  'Como estrategia de trabajo: considerarla a través de decisiones metodológicas en la implementación de dinámicas, diseño de materiales y gestión de apoyos, dando respuesta a las diversidades funcionales de los estudiantes.': 'Como estratégia de trabalho: considerá-la através de decisões metodológicas na implementação de dinâmicas, desenho de materiais e gestão de apoios, dando resposta às diversidades funcionais dos estudantes.',
  'Inclusión Educativa': 'Inclusão Educativa',
  'Se trata de una cultura educativa basada en garantizar la igualdad de oportunidades y circunstancias para el aprendizaje y la continuidad de los estudiantes en el entorno escolar. Se toman decisiones en diferentes niveles: práctica educativa, política educativa y cultura educativa, procurando evitar los riesgos de exclusión.': 'Se trata de uma cultura educativa baseada em garantir a igualdade de oportunidades e circunstâncias para a aprendizagem e a continuidade dos estudantes no entorno escolar. Se tomam decisões em diferentes níveis: prática educativa, política educativa e cultura educativa, procurando evitar os riscos de exclusão.',
  'Objetivos de la implementación de los indicadores de calidad': 'Objetivos da implementação de indicadores de qualidade',
  'Identificar': 'Identificar',
  'aquellas prácticas educativas que dan como resultado el logro de los objetivos buscados, definiendo el nivel de impacto en relación a las posibilidades que dan para la participación del estudiante con DM/DS, con el fin de poder replicarlos en otras instancias similares.': 'as práticas educacionais que resultam no alcance dos objetivos pretendidos, definindo o nível de impacto em relação às possibilidades que dão para a participação do aluno com DMu/SC, de forma a poder replicá-las noutras instâncias semelhantes.',
  'Retroalimentar': 'Fornecer feedback',
  'al usuario del instrumento a través de la información que arroja, permitiéndole la toma de decisiones y el seguimiento según los principios sobre el que se sostiene el mismo.': 'ao usuário do instrumento através da informação que este disponibiliza, permitindo-lhe tomar decisões e acompanhar de acordo com os princípios em que se baseia.',
  'Indicadores de calidad de Perkins - Características generales': 'Indicadores de qualidade da perkins Características gerais',
  'Los indicadores están organizados en siete categorías de acuerdo a los tópicos que se evalúan.': 'Os indicadores estão organizados em sete categorias de acordo com os tópicos que se avalia.',
  'Categorías de indicadores': 'Categorias de indicadores',
  'Categoría 1 al 4: estas categorías agrupan indicadores que se focalizan en la escena más general de la escuela. A través de ellos se puede visibilizar participación, decisiones y organización que, a través de los acuerdos articulados, nos acercan a entender aspectos generales de la cultura escolar': 'Categorias de 1 a 4: estas categorias agrupam indicadores que se focalizam na cena mais geral da escola. Através deles se pode visibilizar participação, decisões e organização que, através dos acordos articulados, nos aproximam para entender aspectos gerais da cultura escolar.',
  'Categorías del 5 al 7: estas categorías si bien contribuyen a un análisis general orientan la observación de individualidades situadas en la relación maestro-estudiante-familia de personas con DM/DS.': 'Categorias de 5 a 7: estas categorias embora contribuam para uma análise mais geral, orientam a observação de individualidades situadas na relação professor-estudante-família de pessoas com DMu/DS.',
  '1- Conocimiento sobre el estudiante: conocimiento, toma de decisiones e instrumentación de herramientas para recolectar información sobre el estudiante. Esto permite valorarlo y a partir de ello diseñar planes de trabajo.': '1- Conhecimento sobre o estudante: conhecimento, tomada de decisões e instrumentação de ferramentas para coletar informação sobre o estudante. Isto permite avaliar e a partir daí desenhar planos de trabalho.',
  '2- Planificación, actividades y contenidos: vinculación de actividades, propuestas, estrategias y contenidos con las demandas y necesidades educativas.': '2- Planejamento, atividades e conteúdos: vinculação de atividades, propostas, estratégias e conteúdos com as demandas e necessidades educacionais.',
  '3- Organización del entorno educativo: aspectos sobre la organización del entorno en el que se desarrolla la práctica educativa a partir de la administración de variables tiempo y contexto.': '3- Organização do entorno educacional: aspectos sobre a organização do entorno no qual se desenvolve a prática educacional a partir da administração de variáveis de tempo e contexto.',
  '4- Gestión escolar: determinaciones sobre la disposición de los recursos humanos, implementación de estrategias y trabajo en equipo para el desarrollo de los programas educativos de los estudiantes.': '4- Gestão escolar: determinações sobre a disposição dos recursos humanos, implementação de estratégias e trabalho em equipe para o desenvolvimento dos programas educacionais dos alunos.',
  '5- Sobre los profesionales: indicadores sobre el conocimiento específico de los profesionales para dar respuesta a las necesidades de los estudiantes, las dinámicas de trabajo colaborativo implementadas y la participación de la familia.': '5- Sobre profissionais: indicadores sobre o conhecimento específico dos profissionais para responder às necessidades dos alunos, à dinâmica de trabalho colaborativo implementada e à participação da família.',
  '6- Sobre la participación de los estudiantes: comportamientos, manifestaciones e interacciones comunicativas observados en los estudiantes que son producto de las estrategias, decisiones y planes implementados en la escuela.': '6- Sobre a participação de estudantes: comportamentos, manifestações e interações comunicativas observadas em estudantes que são o produto das estratégias, decisões e planos implementados na escola.',
  '7- Sobre la familia: participación de la familia en los diferentes niveles y como miembros activos del equipo escolar.': '7- Sobre la familia: participación de la familia en los diferentes niveles y como miembros activos del equipo escolar.',
  'Niveles de logro definidos por el resultado alcanzado': 'Níveis de conquista definidos pelo resultado alcançado',
  'Para cumplimentar con los objetivos se definen los siguientes niveles de impacto': 'Para cumprir os objetivos, os seguintes níveis de impacto são definidos',
  'se identifican prácticas, acciones, decisiones o materiales que han sido implementados adecuadamente sólo en algún aspecto o por alguna persona de la comunidad educativa. Se desarrollan en un nivel específico y en tiempos discontinuos.': 'práticas, ações, decisões ou materiais que foram implementados adequadamente apenas em algum aspecto ou por alguém da comunidade educacional são identificados. Eles se desenvolvem em um nível específico e em tempos descontínuos.',
  'se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente, en forma discontinua pero recurrente, realizadas por varias personas y en diversas áreas o escenarios.': 'práticas, ações, decisões ou materiais são implementados adequadamente, de forma descontínua, mas recorrentes, feitos por várias pessoas e em várias áreas ou cenários.',
  'se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente y en forma más sistemática y periódica. Se apoya en diferentes niveles de gestión e involucra a varios actores, por lo que alcanza a la mayoría de las áreas o escenarios/espacios.': ' práticas, ações, decisões ou materiais implementados corretamente e de maneira mais sistemática e periódica são identificados. Se baseia em diferentes níveis de gerenciamento e envolve vários atores, por isso atinge a maioria das áreas ou cenários/espaços.',
  'se identifican decisiones, acciones, proyectos de intención y prácticas institucionales innovadoras documentadas que involucran a todas las áreas y espacios de la escuela y a todos los miembros de la comunidad educativa.': 'são identificadas decisões, ações, projetos de intenção e práticas institucionais inovadoras que envolvem todas as áreas e espaços da escola e todos os membros da comunidade educacional.',
  'existe evidencia de decisiones, acciones y proyectos orientados a la creación y desarrollo de estrategias innovadoras en forma habitual. Esto define un rasgo identitario particular de liderazgo en relación a otras prácticas, instituciones y al impacto en la comunidad.': 'há evidências de decisões, ações e projetos orientados para a criação e desenvolvimento de estratégias inovadoras regularmente. Isso define um recurso de identidade específico da liderança em relação a outras práticas, instituições e impacto na comunidade.',
  'NOTA: LA VALORACIÓN DE UN INDICADOR EN UN NIVEL SUPONE QUE TODOS LOS NIVELES ANTERIORES HAN SIDO SUPERADOS.': 'NOTA: A AVALIAÇÃO DE UM INDICADOR EM UM NÍVEL PRESSUPÕE QUE TODOS OS NÍVEIS ANTERIORES FORAM SUPERADOS',
  'Implementación': 'Implementação',
  'La aplicación puede seguir dos estrategias: la observación directa Y/o la entrevista. Cada estrategia se complementa entre sí para poder recoger la información necesaria.': 'A aplicação pode seguir duas estratégias: observação direta e/ou a entrevista. Essas estratégias são complementares e permitem coletar as informações necessárias.',
  'La información obtenida de la observación y/o de la entrevista se introduce en una hoja de cálculo en un proceso similar al seguido con la versión anterior de los PQI (2018). Cada criterio de evaluación se valora por separado según los diferentes niveles de logro. La herramienta calcula una puntuación para cada criterio, indicador y categoría, para posteriormente estimar el progreso general de un programa.': 'As informações obtidas da observação e/ou da entrevista são introduzidas em uma planilha em um processo semelhante ao seguido com a versão anterior do PQI (2018). Cada critério de avaliação é avaliado separadamente de acordo com os diferentes níveis de conquista. A ferramenta calcula uma pontuação para cada critério, indicador e categoria, para estimar posteriormente o progresso geral de um programa.',
  'Bienvenidos a la Aplicación Evaluadora de Indicadores de Calidad de Perkins': 'Bem-vindo ao aplicativo Avaliador de Indicadores de Qualidade Perkins',
  'Ingresa con tu cuenta': 'Entre com sua conta',
  'Email o usuario': 'Email ou usuário',
  '¿Olvidaste tu contraseña?': 'Você esqueceu sua senha?',
  'Iniciar sesión': 'Iniciar sessão',
  'Políticas de Privacidad': 'Políticas de Privacidade',
  'Ingresa tu dirección de email y te enviaremos un link de recuperación': 'Digite seu endereço de e-mail e enviaremos um link de recuperação',
  'Enviar email': 'Enviar email',
  'Volver': 'Retornar',
  '¿Estás seguro de eliminar esta nota': 'Tem certeza de que deseja remover esta nota',
  '¿Estás seguro de eliminar esta respuesta': 'Tem certeza de que deseja remover esta resposta',
  '¿Estás seguro de eliminar esta meta': 'Tem certeza de que deseja remover esta meta',
  '¿Estás seguro de eliminar esta actividad': 'Tem certeza de que deseja remover esta atividade',
  '¿Estás seguro de eliminar este resultado': 'Tem certeza de que deseja remover este resultado',
  '¿Estás seguro de eliminar esta observación': 'Tem certeza de que deseja remover esta observação',
  'Preguntas y respuestas' : 'Preguntas y respostas',
  'Metas y actividades' : 'Metas e atividades',

  //user validations
  'Debe tener un mínimo de 8 caracteres': 'Deve ter um mínimo de 8 caracteres.',
  'Ya existe un usuario registrado con este nombre.' : 'Já existe um usuário registrado com este nome.',
  'Las contraseñas no coinciden':'As senhas não correspondem.',
  'Ha ocurrido un error, intente nuevamente más tarde' : 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  'Ya existe una cuenta registrada con este correo electrónico.' : 'Já existe uma conta registrada com este endereço de e-mail.',
  'There is already a representative for the selected country' : 'Já existe um representante para o país selecionado.',
  'There is already a representative for the selected region' : 'Já existe um representante para a região selecionada.',
  'There is already a representative for the selected school' : 'Já existe um representante para a escola selecionada.',
  'Debe asignar al menos un elemento' : 'Você deve atribuir pelo menos um item.',
  'You must specify a country' : 'Você deve especificar um país.',
  'You must specify a region' : 'Você deve especificar uma região.',
  'You must specify a school' : 'Você deve especificar uma escola.',
  'Nombre del archivo demasiado largo': 'Nome do arquivo muito longo',
  'The file extension is not valid': 'A extensão do arquivo não é válida',
  'The file exceeds the size allowed for its extension': 'O arquivo excede o tamanho permitido para sua extensão',

  //Alerts from backend/ validations
  'You do not have permission to delete this comment.' : 'Você não tem permissão para excluir este comentário.',
  'Ensure this field has no more than 1000 characters.': 'Certifique-se de que este campo tenha pelo menos 5 caracteres.',
  'Ensure this field has at least 5 characters.' : '',
  'Demasiados caracteres' : 'Muitos caracteres.',
  'Ocurrió un problema, vuelva a intentar más tarde': 'Ocorreu um problema, tente novamente mais tarde',
  'This field may not be blank.': 'Este campo não pode ficar em branco.',
  'This password is too short. It must contain at least 8 characters.': 'Esta senha é muito curta. Deve conter pelo menos 8 caracteres.',
  'Ensure this field has at least 8 characters.': 'Certifique-se de que este campo tenha pelo menos 8 caracteres.',

  //otras
  'Ingrese un mínimo de 3 caracteres' : 'Digitar um mínimo de 3 caracteres',
  '¿Eliminar el archivo:' : '¿Eliminar arquivo:',
  'No hay archivos cargados' : 'Nenhum arquivo enviado',
  'Descarga clickeando en los iconos de los archivos': 'Baixe clicando nos ícones dos arquivos',
  'Link de recuperación enviado a la dirección de email ingresada.': 'Link de recuperação enviado para o endereço de e-mail informado.',
  'No existe usuario vinculado con el email ingresado.': 'Não há nenhum usuário vinculado ao e-mail informado.',
  'Recuperar contraseña': 'Recuperar senha',
  'Token inválido o expirado': 'Token inválido ou expirado',
  'Por favor intentelo con un nuevo token' : 'Por favor, tente com um novo token',
  'Ingresa tu nueva contraseña' : 'Digite sua nova senha',
  'Nueva contraseña': 'Nova senha',
  'Imprimir sección': 'Imprimir seção',
  '¿Cambiar contraseña?' : 'Alterar a senha?',
  'cambiar rol': 'Editar papel',
  'cambiar el rol eliminaría relaciones con paises, regiones y escuelas.' : 'Mudar o papel eliminaria relações com países, regiões e escolas.',
  'El archivo que se desea cargar debe tener alguna de las siguientes extensiones y tamaños máximos': 'O arquivo que você deseja enviar deve ter uma das seguintes extensões e tamanhos máximos',
  'para': 'para',
  'Escuela no encontrada.': 'Escola não encontrada.',

  //Modal impresion
  '¿Problemas para imprimir los gráficos': 'Problemas para imprimir os gráficos',
  'Al presionar el botón de imprimir y dirigirse a la interfaz de impresión, puede ver diferencias en el tamaño y disposicion de los gráficos y de la tipografía, según el navegador web que usted utilize (recomendamos utilizar Chrome o Firefox).': 'Ao pressionar o botão de impressão e ir para a interface de impressão, você pode ver diferenças no tamanho e disposição dos gráficos e da tipografia, dependendo do navegador web que você utiliza (recomendamos o uso do Chrome ou Firefox).',
  'También puede haber variaciones segun el zoom que haya fijado en su navegador, se recomienda usar el valor por defecto (100%) al momento de presionar el botón de "imprimir gráficos".': 'Também pode haver variações dependendo do zoom que você definiu em seu navegador, é recomendado usar o valor padrão (100%) ao pressionar o botão de “imprimir gráficos”.',
  'En cualquiera de los casos, cuando presione el botón, se abrira el modal de impresión que es un componente que no depende de nuestra aplicación, sino del navegador.': 'Em qualquer caso, quando você pressiona o botão, o modal de impressão se abre, que é um componente que não depende de nossa aplicação, mas do navegador.',
  'En todos los navegadores encontrará aqui una versión distinta de esta funcionalidad, pero todos poseen algunas funciones útiles con las cuales puede darle mas personalización a la disposicion y tamaño de los elementos.': 'Em todos os navegadores você encontrará aqui uma versão diferente desta funcionalidade, mas todos possuem algumas funções úteis com as quais você pode dar mais personalização à disposição e tamanho dos elementos.',
  'Por ejemplo, puede ajustar los márgenes de la página, seleccionar si desea imprimir en orientación vertical u horizontal, y decidir si desea imprimir los encabezados y pies de página del navegador.': 'Por exemplo, você pode ajustar as margens da página, selecionar se deseja imprimir em orientação retrato ou paisagem, e decidir se deseja imprimir os cabeçalhos e rodapés do navegador.',
  'Además, algunos navegadores ofrecen la opción de escalar el contenido de la página para que se ajuste al tamaño del papel. Esto puede ser útil si encuentra que el contenido de la página es demasiado grande o demasiado pequeño cuando se imprime.': 'Além disso, alguns navegadores oferecem a opção de escalar o conteúdo da página para se ajustar ao tamanho do papel. Isso pode ser útil se você achar que o conteúdo da página é muito grande ou muito pequeno quando impresso.',
  'Ejemplo en Google Chrome': 'Exemplo no Google Chrome',

  //Reports
  'Reportes': 'Relatórios',
  'Reporte': 'Relatório',
  'Time': 'Hora',
  'Acuerdos': 'Acordos',
  'Crear reporte': 'Criar relatório',
  '¿Estás seguro de eliminar este reporte': 'Tem certeza de que deseja excluir este relatório',
  'No hay reportes asignados para este país.' : 'Não há relatórios atribuídos a este país.',
  'No hay reportes asignados para esta región.' : 'Não há relatórios atribuídos para esta região.',

}
